import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/urls.config'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        checkIsAuth: builder.mutation<{ status: boolean; email: string }, void>({
            query: () => urlConfig.auth,
        }),
        checkIsAdmin: builder.mutation<{ status: boolean; email: string }, void>({
            query: () => urlConfig.adminStatus,
        }),
    }),
})

export const { useCheckIsAuthMutation, useCheckIsAdminMutation } = authApi
