import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/urls.config'
import { ProfileType } from 'types/profile.type'

export const profileApi = createApi({
    reducerPath: 'profileApi',
    keepUnusedDataFor: 0,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getProfile: builder.mutation<
            unknown,
            {
                email: string
            }
        >({
            query: data => ({
                url: `${urlConfig.profileBooking}?email=${data.email}`,
            }),
        }),
        getData: builder.mutation<ProfileType.GetDataResponse, string>({
            query: email => ({
                url: `${urlConfig.myHouseCounters}?email=${email}`,
            }),
        }),
        getPeriods: builder.mutation<unknown, string>({
            query: email => ({
                url: `${urlConfig.profileInitialPeriods}?email=${email}`,
            }),
        }),
        getCountersData: builder.mutation<
            unknown,
            {
                email: string
                countType: string
                year: string | number
                month: string | number
            }
        >({
            query: ({ email, countType, year, month }) => ({
                url: `${urlConfig.profileCounters}?email=${email}&counterType=${countType}&year=${year}&month=${month}`,
            }),
        }),
        getRevenue: builder.mutation<
            unknown,
            {
                email: string
                quarter: string | number
                year: string | number
            }
        >({
            query: ({ email, quarter, year }) => ({
                url: `${urlConfig.invoiceRevenue}?email=${email}&quarter=${quarter}&year=${year}`,
            }),
        }),
        getQuarters: builder.mutation<unknown, string>({
            query: (email: string) => ({
                url: `${urlConfig.initialQuarters}?email=${email}`,
            }),
        }),
        getQuarterCounts: builder.mutation<
            unknown,
            {
                email: string
                quarter: string | number
                year: string | number
            }
        >({
            query: ({ email, quarter, year }) => ({
                url: `${urlConfig.quartersCount}?email=${email}&quarter=${quarter}&year=${year}`,
            }),
        }),
    }),
})

export const {
    useGetProfileMutation,
    useGetDataMutation,
    useGetPeriodsMutation,
    useGetCountersDataMutation,
    useGetRevenueMutation,
    useGetQuartersMutation,
    useGetQuarterCountsMutation,
} = profileApi
