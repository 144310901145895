import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { memo } from 'react'

interface NumberSelectProps {
    data: number[]
    selected: number
    setSelected: React.Dispatch<React.SetStateAction<number>>
    label?: string
    placeholder?: string
}
export const NumberSelect: React.FC<NumberSelectProps> = memo(({ data, selected, setSelected, label, placeholder }) => {
    return (
        <div className="flex flex-col gap-1.5">
            <p>{label}:</p>
            <Select value={String(selected)} onValueChange={value => setSelected(Number(value))}>
                <SelectTrigger className="w-fit min-w-[100px]">
                    <SelectValue placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent>
                    {data?.map(num => (
                        <SelectItem key={num} value={String(num)} className="cursor-pointer">
                            {num}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
})
NumberSelect.displayName = 'NumberSelect'
