import { Button } from 'components/ui/button'
import { urlConfig } from 'config/urls.config'
import { memo } from 'react'
import { Link } from 'react-router-dom'

interface HomeWelcomeProps {}
export const HomeWelcome: React.FC<HomeWelcomeProps> = memo(() => {
    return (
        <div className="mx-auto flex max-w-2xl flex-col items-center justify-center pt-20">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                <div className="text-muted-foreground ring-muted-foreground/10 hover:ring-muted-foreground/20 relative rounded-full px-3 py-1 text-sm leading-6 ring-1">
                    Welcome to Nova!{' '}
                    <Link to={urlConfig.guest} className="text-primary font-semibold">
                        <span className="absolute inset-0" aria-hidden="true" />
                        My House <span aria-hidden="true">&rarr;</span>
                    </Link>
                </div>
            </div>
            <div className="text-center">
                <h1 className="text-foreground text-4xl font-bold tracking-tight sm:text-6xl">
                    Wanage your home and bookings in one place
                </h1>
                <p className="text-muted-foreground mt-6 text-lg leading-8">
                    View real-time and historical data on your home's electricity, heat, cooling and water usage. See where you
                    can save money and lower environmental impact.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Button asChild>
                        <Link to={urlConfig.invoices}>Get started</Link>
                    </Button>
                    <Link to={urlConfig.about} className="text-foreground text-sm font-semibold leading-6">
                        Learn more <span aria-hidden="true">→</span>
                    </Link>
                </div>
            </div>
        </div>
    )
})
HomeWelcome.displayName = 'HomeWelcome'
