import dayjs from 'dayjs'
// import localeData from 'dayjs/plugin/localeData'
// import weekOfYear from 'dayjs/plugin/weekOfYear'

// dayjs.extend(localeData)
// dayjs.extend(weekOfYear)

type GetLocaleDateTime = (date?: string) => string
export const getLocaleDateTime: GetLocaleDateTime = date => dayjs(date).format('DD MMMM YYYY, HH:mm:ss')

type GetLocaleDate = (date?: string) => string
export const getLocaleDate: GetLocaleDate = date => dayjs(date).format('DD MMMM YYYY')
