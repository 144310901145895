import { FullPageLoader } from 'components/full-page-loader'
import { urlConfig } from 'config/urls.config'
import { memo } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'redux/store'

interface LoginPageProps {}
export const LoginPage: React.FC<LoginPageProps> = memo(() => {
    const { isAuth } = useAppSelector(state => state.auth)

    if (isAuth) {
        return <Navigate to={urlConfig.main} />
    }
    if (!isAuth) {
        window.location.href = `${urlConfig.baseURL}${urlConfig.login}`
    }

    return <FullPageLoader />
})
LoginPage.displayName = 'LoginPage'
