import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { CURRENCY } from 'config/constants.config'
import { memo, useMemo } from 'react'
import { Badge } from 'components/ui/badge'

interface InvoicesSummaryProps {
    rent: number
    bookingFee: number
    totaslHouseConsumption: number
    guestsConsumption: number
}
export const InvoicesSummary: React.FC<InvoicesSummaryProps> = memo(
    ({ rent, bookingFee, totaslHouseConsumption, guestsConsumption }) => {
        const totalOwnerRevenue = useMemo(
            () => rent - bookingFee - totaslHouseConsumption + guestsConsumption,
            [bookingFee, guestsConsumption, rent, totaslHouseConsumption],
        )
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Summary</CardTitle>
                </CardHeader>
                <CardContent>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Rent</TableHead>
                                <TableHead>Commissions</TableHead>
                                <TableHead>Booking fee</TableHead>
                                <TableHead>Total house consumption</TableHead>
                                <TableHead>Guests consumption</TableHead>
                                <TableHead>Total revenue for owner</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    + {rent?.toFixed(2) ?? 0} {CURRENCY}
                                </TableCell>
                                <TableCell>- N/a</TableCell>
                                <TableCell>
                                    - {bookingFee?.toFixed(2) ?? 0} {CURRENCY}
                                </TableCell>
                                <TableCell>
                                    - {totaslHouseConsumption?.toFixed(2) ?? 0} {CURRENCY}
                                </TableCell>
                                <TableCell>
                                    + {guestsConsumption?.toFixed(2) ?? 0} {CURRENCY}
                                </TableCell>
                                <TableCell>
                                    <Badge variant="secondary" className="text-base font-medium">
                                        {totalOwnerRevenue?.toFixed(2) ?? 0} {CURRENCY}
                                    </Badge>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        )
    },
)
InvoicesSummary.displayName = 'InvoicesSummary'
