import { RequireAuth } from 'components/hoc/require-auth'
import { memo } from 'react'
import { Outlet } from 'react-router-dom'

interface AuthLayoutProps {}
export const AuthLayout: React.FC<AuthLayoutProps> = memo(() => {
    return (
        <RequireAuth>
            <Outlet />
        </RequireAuth>
    )
})
AuthLayout.displayName = 'AuthLayout'
