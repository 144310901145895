import { Badge } from 'components/ui/badge'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { CURRENCY, ENERGUY_UNIT } from 'config/constants.config'
import { getLocaleDate } from 'config/dayjs.config'
import { memo } from 'react'
import { ProfileSliceState } from 'redux/slices/profile.slice'

interface InvoicesHotelGuestsUsageProps {
    guestsUsage: ProfileSliceState['guestsUsage']
}
export const InvoicesHotelGuestsUsage: React.FC<InvoicesHotelGuestsUsageProps> = memo(({ guestsUsage }) => {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Hotel guests usage</CardTitle>
            </CardHeader>
            <CardContent>
                <Table className="table-fixed">
                    <TableHeader>
                        <TableRow>
                            <TableHead>Date</TableHead>
                            <TableHead>Booking number</TableHead>
                            <TableHead>Electricity</TableHead>
                            <TableHead>Heat</TableHead>
                            <TableHead>Cooling</TableHead>
                            <TableHead>Water</TableHead>
                            <TableHead>Rent</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {guestsUsage?.excludedOwnersReservations?.map(reservation => {
                            const groupedRentItem = guestsUsage.groupedRent[reservation.bookingNumber]

                            if (!groupedRentItem) {
                                return null
                            }

                            const rentValue =
                                groupedRentItem.reduce((acc, curr) => {
                                    return acc + Number(curr.billingServiceDay)
                                }, 0) / groupedRentItem.length

                            return (
                                <TableRow key={reservation.SourceBookingID + reservation.spaceID + reservation.dataDate}>
                                    <TableCell>{getLocaleDate(reservation.dataDate?.toString())}</TableCell>
                                    <TableCell>{reservation.bookingNumber ?? '--'}</TableCell>
                                    <TableCell>
                                        {Number(Number(reservation.consumptionElectricity)?.toFixed(2))?.toFixed(2) ?? 0}{' '}
                                        {ENERGUY_UNIT}
                                    </TableCell>
                                    <TableCell>
                                        {Number(reservation.consumptionHeat).toFixed(2) ?? 0} {ENERGUY_UNIT}
                                    </TableCell>
                                    <TableCell>
                                        {Number(reservation.consumptionCooling).toFixed(2) ?? 0} {ENERGUY_UNIT}
                                    </TableCell>
                                    <TableCell>
                                        {Number(reservation.consumptionWater).toFixed(2) ?? 0} m<sup>3</sup>
                                    </TableCell>
                                    <TableCell>
                                        {rentValue?.toFixed(2) ?? 0} {CURRENCY}
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                        {/* summary */}

                        <TableRow>
                            <TableCell colSpan={2} className="font-bold">
                                Summary
                            </TableCell>
                            <TableCell>
                                {guestsUsage?.guestsElectricitySum?.toFixed(2)} /{' '}
                                {guestsUsage?.guestsElectricityConsumption?.toFixed(2)} {CURRENCY}
                            </TableCell>
                            <TableCell>
                                {guestsUsage?.guestsHeatSum?.toFixed(2)} / {guestsUsage?.guestsHeatConsumption?.toFixed(2)}{' '}
                                {CURRENCY}
                            </TableCell>
                            <TableCell>
                                {guestsUsage?.guestsCoolingSum?.toFixed(2)} / {guestsUsage?.guestsCoolingConsumption?.toFixed(2)}{' '}
                                {CURRENCY}
                            </TableCell>
                            <TableCell>
                                {guestsUsage?.guestsWaterSum?.toFixed(2)} / {guestsUsage?.guestsWaterConsumption?.toFixed(2)}{' '}
                                {CURRENCY}
                            </TableCell>
                            <TableCell>
                                {guestsUsage?.billingServiceSum?.toFixed(2)} {CURRENCY}
                            </TableCell>
                        </TableRow>

                        {/* total */}

                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell>
                                <Badge variant="secondary" className="text-base font-medium">
                                    Total: {guestsUsage?.guestsUsageTotal?.toFixed(2) ?? 0} {CURRENCY}
                                </Badge>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
})
InvoicesHotelGuestsUsage.displayName = 'InvoicesHotelGuestsUsage'
