import { FullPageLoader } from 'components/full-page-loader'
import { urlConfig } from 'config/urls.config'
import { memo, useEffect } from 'react'
import { clearBookingsAction } from 'redux/slices/profile.slice'
import { useAppDispatch } from 'redux/store'

interface AdminPageProps {}

export const AdminPage: React.FC<AdminPageProps> = memo(() => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(clearBookingsAction())
        window.location.href = `${urlConfig.baseURL}${urlConfig.admin}`
    }, [dispatch])

    return <FullPageLoader />
})

AdminPage.displayName = 'AdminPage'
