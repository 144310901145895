import { Table } from '@tanstack/react-table'
import { X } from 'lucide-react'
import { Children, cloneElement } from 'react'
import { cn } from 'utils/cn'
import { Button } from './button'
import { DataTableFacetedFilter } from './data-table-faceted-filter'
import { DataTableInputFilter } from './data-table-input-filter'
import { DataTableView } from './data-table-view'

type AcceptedChildren = React.ReactElement<typeof DataTableInputFilter | typeof DataTableFacetedFilter>

interface DataTableToolbarProps<TData> extends React.ComponentProps<'div'> {
    table?: Table<TData>
    isClearVisible?: boolean
    onClear?: () => void
    children?: AcceptedChildren | AcceptedChildren[]
}

export const DataTableToolbar = <TData,>({
    table,
    isClearVisible,
    onClear,
    children,
    className,
    ...props
}: DataTableToolbarProps<TData>) => {
    if (!table) {
        return null
    }

    const isFiltered = table.getState().columnFilters.length > 0
    const displayClear = isFiltered || isClearVisible

    const handleClear = () => {
        table.resetColumnFilters()
        onClear?.()
    }

    return (
        <div {...props} className={cn('flex items-center justify-between', className)}>
            <div className="flex flex-1 flex-wrap items-center gap-2">
                {Children.map(children, child => cloneElement(child as React.ReactElement, { table }))}
                {displayClear && (
                    <Button variant="ghost" size="sm" onClick={handleClear}>
                        Clear
                        <X className="ml-2 h-4 w-4" />
                    </Button>
                )}
            </div>
            <DataTableView table={table} />
        </div>
    )
}
