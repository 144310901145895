import { memo, useEffect } from 'react'
import { useAppSelector } from 'redux/store'
import { getSystemTheme } from 'utils/get-system-theme'

interface ThemeProviderProps {}
export const ThemeProvider: React.FC<React.PropsWithChildren<ThemeProviderProps>> = memo(({ children }) => {
    const { theme } = useAppSelector(state => state.theme)

    useEffect(() => {
        const mutatedTheme = theme === 'system' ? getSystemTheme() : theme
        if (document.body.classList.contains('dark')) {
            document.body.classList.remove('dark')
        }
        if (mutatedTheme === 'dark') {
            document.body.classList.add('dark')
        }
    }, [theme])

    return children
})
ThemeProvider.displayName = 'ThemeProvider'
