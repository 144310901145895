import { Badge } from 'components/ui/badge'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { CURRENCY, ENERGUY_UNIT, WATER_UNIT } from 'config/constants.config'
import { memo, useMemo } from 'react'
import { ProfileSliceState } from 'redux/slices/profile.slice'

interface InvoicesInvoicePeriodProps {
    data: ProfileSliceState['invoicePeriodData']
    currentQuarterString: string
}
export const InvoicesInvoicePeriod: React.FC<InvoicesInvoicePeriodProps> = memo(({ data, currentQuarterString }) => {
    const EHCWArr = useMemo(
        () => [
            {
                name: 'Electricity',
                ...data?.electricity,
            },
            {
                name: 'Heat',
                ...data?.heat,
            },
            {
                name: 'Cooling',
                ...data?.cooling,
            },
            {
                name: 'Water',
                ...data?.water,
            },
        ],
        [data?.cooling, data?.electricity, data?.heat, data?.water],
    )

    return (
        <Card>
            <CardHeader>
                <CardTitle>Invoice period</CardTitle>
                <CardDescription>{currentQuarterString}</CardDescription>
            </CardHeader>
            <CardContent>
                <Table className="table-fixed">
                    <TableHeader>
                        <TableRow>
                            <TableHead />
                            <TableHead>Counter starts</TableHead>
                            <TableHead>Counter ends</TableHead>
                            <TableHead>Quarter usage</TableHead>
                            <TableHead>Quarter cost</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {EHCWArr.map(item => (
                            <TableRow key={item.name}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>
                                    {item.counterStarts?.toFixed(2)} {item.name === 'Water' ? WATER_UNIT : ENERGUY_UNIT}
                                </TableCell>
                                <TableCell>
                                    {item.counterEnds?.toFixed(2)} {item.name === 'Water' ? WATER_UNIT : ENERGUY_UNIT}
                                </TableCell>
                                <TableCell>
                                    {item.quarterUsage?.toFixed(2)} {item.name === 'Water' ? WATER_UNIT : ENERGUY_UNIT}
                                </TableCell>
                                <TableCell>
                                    {item.quarterCost?.toFixed(2)} {CURRENCY}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell>
                                <Badge variant="secondary" className="text-base font-medium">
                                    Total: {data?.total?.toFixed(2) ?? 0} {CURRENCY}
                                </Badge>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
})
InvoicesInvoicePeriod.displayName = 'InvoicesInvoicePeriodCard'
