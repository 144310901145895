import { Column } from '@tanstack/react-table'
import { ArrowDown, ArrowUp, ChevronsUpDown, EyeOff } from 'lucide-react'
import { cn } from 'utils/cn'
import { defaultIconProps } from '../../config/constants.config'
import { Button } from './button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from './dropdown-menu'

interface DataTableColumnHeaderProps<TData, TValue> extends React.ComponentProps<'div'> {
    column: Column<TData, TValue>
    title: string
    offset?: boolean
    buttonClassName?: string
}

export const DataTableColumnHeader = <TData, TValue>({
    column,
    title,
    offset = true,
    className,
    buttonClassName,
    ...props
}: DataTableColumnHeaderProps<TData, TValue>) => {
    if (!column.getCanSort()) {
        return <div className={cn(className)}>{title}</div>
    }

    const onSortingOff = () => {
        column.toggleSorting(false)
    }
    const onSortingOn = () => {
        column.toggleSorting(true)
    }
    const onVisibilityOff = () => {
        column.toggleVisibility(false)
    }

    return (
        <div {...props} className={cn('flex items-center space-x-2', className)}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button
                        variant="ghost"
                        size="sm"
                        className={cn(
                            'data-[state=open]:bg-accent dark:hover:bg-accent hover:bg-slate-200/70',
                            offset && '-ml-3 h-8',
                            buttonClassName,
                        )}
                    >
                        <span>{title}</span>
                        {column.getIsSorted() === 'desc' ? (
                            <ArrowDown {...defaultIconProps} className="ml-2 h-4 w-4" />
                        ) : column.getIsSorted() === 'asc' ? (
                            <ArrowUp {...defaultIconProps} className="ml-2 h-4 w-4" />
                        ) : (
                            <ChevronsUpDown {...defaultIconProps} {...defaultIconProps} className="ml-2 h-4 w-4" />
                        )}
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                    <DropdownMenuItem onClick={onSortingOff}>
                        <ArrowUp {...defaultIconProps} className="text-muted-foreground/70 mr-2 h-3.5 w-3.5" />
                        Asc
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={onSortingOn}>
                        <ArrowDown {...defaultIconProps} className="text-muted-foreground/70 mr-2 h-3.5 w-3.5" />
                        Desc
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={onVisibilityOff}>
                        <EyeOff {...defaultIconProps} className="text-muted-foreground/70 mr-2 h-3.5 w-3.5" />
                        Hide
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}
