import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { MONTHS } from 'config/constants.config'
import { memo } from 'react'

interface GuestMonthSelectProps extends React.ComponentProps<typeof Select> {
    months: number[]
}
export const GuestMonthSelect: React.FC<GuestMonthSelectProps> = memo(({ months, ...props }) => {
    return (
        <div className="flex flex-col gap-1.5">
            <p>Month:</p>
            <Select {...props}>
                <SelectTrigger className="capitalize">
                    <SelectValue defaultValue={months[0]} />
                </SelectTrigger>
                <SelectContent>
                    {months.map(item => (
                        <SelectItem key={item} value={String(item + 1)} className="cursor-pointer capitalize">
                            {MONTHS[item]}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
})
GuestMonthSelect.displayName = 'GuestMonthSelect'
