import { memo } from 'react'
import { useAppSelector } from 'redux/store'

interface CurrentThemeProps {
    theme: string
}
/**
 * @description render children only in specific theme
 */
export const CurrentTheme: React.FC<React.PropsWithChildren<CurrentThemeProps>> = memo(({ theme, children }) => {
    const { theme: currentTheme } = useAppSelector(state => state.theme)

    return currentTheme === theme ? children : null
})
CurrentTheme.displayName = 'CurrentTheme'
