import { InlineCode } from 'components/ui/code'
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'components/ui/command'
import { toast } from 'components/ui/use-toast'
import { COMMAND_MENU_SHORTCUT, THEMES, defaultIconProps } from 'config/constants.config'
import { urlConfig } from 'config/urls.config'
import { LayoutDashboard, LogIn, LogOut } from 'lucide-react'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { setIsCommandOpenAction, setTipAlreadyShowedAction } from 'redux/slices/command.slice'
import { setThemeAction } from 'redux/slices/theme.slice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { disposableFn } from 'utils/disposable-fn'

const classNameWithIcon = 'flex items-center gap-3'

//

interface CommandMenuProps {}
export const CommandMenu: React.FC<CommandMenuProps> = memo(() => {
    const navigate = useNavigate()
    const { isAuth, isLoading } = useAppSelector(state => state.auth)
    const { isOpen, tipAlreadyShowed } = useAppSelector(state => state.command)
    const dispatch = useAppDispatch()

    const showTipGenerator = useCallback(
        () =>
            disposableFn(() => {
                if (tipAlreadyShowed) {
                    return
                }
                toast({
                    title: 'Cool Tip',
                    description: (
                        <span>
                            Press <InlineCode>{COMMAND_MENU_SHORTCUT}</InlineCode> to open the command menu
                        </span>
                    ),
                })
                dispatch(setTipAlreadyShowedAction(true))
            }),
        [dispatch, tipAlreadyShowed],
    )

    const setCommandOpen = useCallback<(open?: boolean) => void>(
        open => {
            dispatch(setIsCommandOpenAction(open))
        },
        [dispatch],
    )

    const onPageOpen = useCallback(
        (to: string) => {
            return () => {
                setCommandOpen(false)
                navigate(to)
            }
        },
        [navigate, setCommandOpen],
    )

    const onThemeChange = useCallback(
        (newTheme: string) => {
            return () => {
                dispatch(setThemeAction(newTheme))
                setCommandOpen(false)
            }
        },
        [dispatch, setCommandOpen],
    )

    const onTipShow = useMemo(() => showTipGenerator(), [showTipGenerator])

    useEffect(() => {
        const toggle = (e: KeyboardEvent) => {
            if (!(e.key === 'k' && e.metaKey)) {
                return
            }
            e.preventDefault()
            setCommandOpen()
        }
        document.addEventListener('keydown', toggle)
        return () => {
            document.removeEventListener('keydown', toggle)
        }
    }, [dispatch, setCommandOpen])

    useEffect(() => {
        setTimeout(() => {
            onTipShow()
        }, 3000)
    }, [onTipShow])

    return (
        <CommandDialog open={isOpen} onOpenChange={setCommandOpen}>
            <CommandInput placeholder="Type a command or search..." />
            <CommandList className="pb-1">
                <CommandEmpty>No results found</CommandEmpty>

                {/* pages */}
                <CommandGroup heading="Pages">
                    {/* always */}
                    <CommandItem onSelect={onPageOpen(urlConfig.home)} className={classNameWithIcon}>
                        <LayoutDashboard {...defaultIconProps} />
                        <span>Home</span>
                    </CommandItem>
                    <CommandItem onSelect={onPageOpen(urlConfig.about)} className={classNameWithIcon}>
                        <LayoutDashboard {...defaultIconProps} />
                        <span>About</span>
                    </CommandItem>
                    <CommandItem onSelect={onPageOpen(urlConfig.invoices)} className={classNameWithIcon}>
                        <LayoutDashboard {...defaultIconProps} />
                        <span>Invoices</span>
                    </CommandItem>

                    {/* auth */}
                    {isAuth && (
                        <>
                            <CommandItem onSelect={onPageOpen(urlConfig.logout)} className={classNameWithIcon}>
                                <LogOut {...defaultIconProps} />
                                <span>Log out</span>
                            </CommandItem>
                        </>
                    )}

                    {/* not auth */}
                    {!isAuth && (
                        <CommandItem onSelect={onPageOpen(urlConfig.login)} className={classNameWithIcon}>
                            <LogIn {...defaultIconProps} />
                            <span>Log In</span>
                        </CommandItem>
                    )}
                </CommandGroup>

                {/* themes */}
                <CommandGroup heading="Themes">
                    {THEMES.map(theme => (
                        <CommandItem key={theme.name} onSelect={onThemeChange(theme.name)} className={classNameWithIcon}>
                            {theme.icon}
                            <span>
                                Theme: <span className="capitalize">{theme.name}</span>
                            </span>
                        </CommandItem>
                    ))}
                </CommandGroup>
            </CommandList>
        </CommandDialog>
    )
})
CommandMenu.displayName = 'CommandMenu'
