export const urlConfig = {
    baseURL: process.env.REACT_APP_API,
    frontendURL: process.env.FRONTEND_URL,

    main: '/',
    home: '/home',
    about: '/About',
    user: '/user',
    noBookings: '/notExists',
    guest: '/Guest',
    callback: '/callback',

    invoices: '/invoices',
    invoiceRevenue: '/invoiceRevenue',
    initialQuarters: '/invoiceRevenue/initialQuartersValues',
    quartersCount: '/invoiceRevenue/getQuarterCounts',

    profileBooking: '/profile/booking',
    profileEnergy: '/profile/energy',
    profileInitialPeriods: '/profile/initialPeriods',
    profileCounters: 'profile/counters',

    myHouse: '/myHouse',
    myHouseCounters: '/myHouse/counters',

    login: '/login',
    logout: '/logout',
    auth: '/status',
    adminStatus: '/admin/status',
    admin: '/admin',
}
