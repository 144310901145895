export const changeUnits = (unit: string) => {
    switch (unit) {
        case 'Wh':
            return 'kWh'
        case 'm3':
            return 'm3'
        default:
            return ''
    }
}
