import { memo } from 'react'
import { cn } from 'utils/cn'

interface LogoProps extends Partial<React.ComponentProps<'img'>> {
    light?: boolean
    dark?: boolean
}
export const Logo: React.FC<LogoProps> = memo(({ light, dark, className, ...props }) => {
    return (
        <h3 {...props} className={cn('text-foreground text-3xl', className)}>
            NOVA
        </h3>
    )
})
Logo.displayName = 'Logo'
