import { Logo } from 'components/logo'
import { Button } from 'components/ui/button'
import { urlConfig } from 'config/urls.config'
import { HomeProfile } from 'pages/home/components/home-profile'
import { HomeWelcome } from 'pages/home/components/home-welcome'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useCheckIsAuthMutation } from 'redux/api/auth.api'
import { useAppSelector } from 'redux/store'
import { ThemeSelector } from '../../components/theme-selector.tsx'

const navigation = [
    {
        name: 'About',
        href: urlConfig.about,
    },
    {
        name: 'Invoices',
        href: urlConfig.invoices,
    },
    {
        name: 'My House',
        href: urlConfig.guest,
    },
]

export const HomePage = () => {
    const { isAuth } = useAppSelector(state => state.auth)
    const [checkIsAuth] = useCheckIsAuthMutation()

    useEffect(() => {
        checkIsAuth()
    }, [checkIsAuth])

    return (
        <div className="h-full overflow-hidden">
            <header className="inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between p-6 md:px-8" aria-label="Global">
                    <div className="flex md:flex-1">
                        <Link to={urlConfig.home} className="text-md font-bold">
                            <Logo />
                        </Link>
                    </div>

                    <div className="hidden md:flex md:gap-x-12">
                        {navigation.map(item => (
                            <Link key={item.name} to={item.href} className="text-foreground text-sm font-semibold leading-6">
                                {item.name}
                            </Link>
                        ))}
                    </div>
                    <div className="hidden md:flex md:flex-1 md:justify-end">
                        <ThemeSelector />
                        <Button variant="none" asChild className="text-foreground flex gap-1 text-sm font-semibold leading-6">
                            <Link to={isAuth ? urlConfig.logout : urlConfig.login}>
                                <span>Log {isAuth ? 'out' : 'in'}</span> <span aria-hidden="true">&rarr;</span>
                            </Link>
                        </Button>
                    </div>
                </nav>
            </header>

            <div className="relative isolate h-[80%] px-6 pt-14 lg:px-8">
                {/* gradient 1 */}
                <div
                    className="pointer-events-none absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                {/* gradient 1 end */}

                {isAuth ? <HomeProfile /> : <HomeWelcome />}

                {/* gradient 2 */}
                <div
                    className="pointer-events-none absolute inset-x-0 top-[calc(100%-30rem)] -z-10 transform-gpu overflow-hidden blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                {/* gradient 2 end */}
            </div>
        </div>
    )
}
