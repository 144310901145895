import { Button } from 'components/ui/button'
import { Calendar } from 'components/ui/calendar'
import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'
import { DateRange } from 'react-day-picker'
import { cn } from 'utils/cn'
import { Popover, PopoverContent, PopoverTrigger } from './popover'

interface DatePickerProps extends React.ComponentProps<'div'> {
    date: DateRange | undefined
    onDateChange: React.Dispatch<React.SetStateAction<DateRange | undefined>>
}
/**
 * @description Date picker with range
 * const [date, setDate] = useState<DateRange | undefined>()
 * <DatePickerWithRange date={date} onDateChange={setDate} />
 */
export const DatePickerWithRange: React.FC<DatePickerProps> = ({ date, onDateChange, className, ...props }) => {
    return (
        <div {...props} className={cn('grid gap-2', className)}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        size="sm"
                        className={cn('w-[250px] justify-start text-left text-sm font-normal', !date && 'text-muted-foreground')}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date?.from ? (
                            date.to ? (
                                <>
                                    {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                                </>
                            ) : (
                                format(date.from, 'LLL dd, y')
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={onDateChange}
                        numberOfMonths={1}
                    />
                </PopoverContent>
            </Popover>
        </div>
    )
}
