import { FullPageLoader } from 'components/full-page-loader'
import { urlConfig } from 'config/urls.config'
import { memo, useEffect } from 'react'
import { useCheckIsAdminMutation } from 'redux/api/auth.api'
import { useAppSelector } from 'redux/store'

interface RequireAuthProps {}

export const RequireAuth: React.FC<React.PropsWithChildren<RequireAuthProps>> = memo(({ children }) => {
    const { isAuth, isLoading } = useAppSelector(state => state.auth)
    const [checkIsAdmin] = useCheckIsAdminMutation()

    useEffect(() => {
        if (!isAuth) {
            checkIsAdmin()
        }
    }, [checkIsAdmin, isAuth])

    if (!isAuth) {
        window.location.href = `${urlConfig.baseURL}${urlConfig.login}`
        return <FullPageLoader />
    }

    return children
})
RequireAuth.displayName = 'RequireAuth'
