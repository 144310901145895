import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './ui/dropdown-menu.tsx'
import { Button } from './ui/button.tsx'
import { THEMES, themesIcons } from '../config/constants.config.tsx'
import { CurrentTheme } from './hoc/current-theme.tsx'
import { useAppDispatch, useAppSelector } from '../redux/store.ts'
import { useCallback } from 'react'
import { setThemeAction } from '../redux/slices/theme.slice.ts'

interface ThemeSelectoreProps {}
export const ThemeSelector: React.FC<ThemeSelectoreProps> = () => {
    const dispatch = useAppDispatch()
    const { theme } = useAppSelector(state => state.theme)

    const onThemeChange = useCallback(
        (newTheme: string) => {
            return () => {
                dispatch(setThemeAction(newTheme))
            }
        },
        [dispatch],
    )

    return (
        <DropdownMenu>
            <Button variant="ghost" size="icon-sm" asChild>
                <DropdownMenuTrigger>{theme === 'light' ? themesIcons.light : themesIcons.dark}</DropdownMenuTrigger>
            </Button>
            <DropdownMenuContent>
                {THEMES.map(theme => (
                    <DropdownMenuItem key={theme.name} onClick={onThemeChange(theme.name)} className="flex gap-2">
                        <span className="capitalize">{theme.name}</span>
                        <CurrentTheme theme={theme.name}>
                            <div className="bg-foreground h-1.5 w-1.5 rounded-full" />
                        </CurrentTheme>
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
