import { QUARTERS } from 'config/constants.config'

type GetCurrentYear = () => number
export const getCurrentYear: GetCurrentYear = () => new Date().getFullYear()

type GetCurrentQuarter = () => number
export const getCurrentQuarter: GetCurrentQuarter = () => {
    const month = new Date().getMonth()
    return Math.floor(month / 3) + 1
}

type GetQuarterString = (quarter: number) => string
export const getQuarterString: GetQuarterString = (quarter: number) => {
    // quarter => 1-4 (number)
    // -1 => 0-3 (index)
    return QUARTERS?.[quarter - 1] ?? ''
}
