import { Button } from 'components/ui/button'
import { urlConfig } from 'config/urls.config'
import { memo } from 'react'
import { Link } from 'react-router-dom'

interface NoBookingsPageProps {}
export const NoBookingsPage: React.FC<NoBookingsPageProps> = memo(() => {
    return (
        <div className="animate-in fade-in-0 flex h-full min-h-screen w-full flex-col items-center justify-center gap-8 transition-all duration-300">
            <div className="flex flex-col gap-3">
                <h1 className="max-w-xl text-center text-5xl leading-tight">No bookings found</h1>
                <p className="text-muted-foreground max-w-lg text-center leading-tight">
                    No owner bookings found with specified email adress. Please try again with a different email adress
                </p>
            </div>
            <Button asChild>
                <Link to={urlConfig.home}>Go Home</Link>
            </Button>
        </div>
    )
})
NoBookingsPage.displayName = 'NoBookingsPage'
