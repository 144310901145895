import { CommandMenu } from 'components/command-menu'
import { ThemeProvider } from 'components/hoc/theme-provider'
import { TailwindIndicator } from 'components/tailwind-indicator'
import { Toaster } from 'components/ui/toaster'
import { ErrorPage } from 'pages/error-page'
import { memo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Routes } from 'routes'

export const App: React.FC = memo(() => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <Routes />
            {/*  */}
            <Toaster />
            <CommandMenu />
            <ThemeProvider />
            {/*<TailwindIndicator />*/}
        </ErrorBoundary>
    )
})
App.displayName = 'App'
