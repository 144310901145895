import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { COUNTS } from 'config/constants.config'
import { memo } from 'react'

interface GuestCountSelectProps extends React.ComponentProps<typeof Select> {}
export const GuestCountSelect: React.FC<GuestCountSelectProps> = memo(({ ...props }) => {
    return (
        <div className="flex flex-col gap-1.5">
            <p>Select counts by:</p>
            <Select {...props}>
                <SelectTrigger className="capitalize">
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    {COUNTS.map(item => (
                        <SelectItem key={item} value={item} className="cursor-pointer capitalize">
                            {item}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
})
GuestCountSelect.displayName = 'GuestCountSelect'
