import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Table, TableBody, TableCell, TableRow } from 'components/ui/table'
import { memo } from 'react'

interface InvoicesRevenueFromRentProps {
    rent: number
    bookingFee: number
    house: number
}
export const InvoicesRevenueFromRent: React.FC<InvoicesRevenueFromRentProps> = memo(({ rent, house, bookingFee }) => {
    return (
        <Card className="min-w-[400px]">
            <CardHeader>
                <CardTitle>Revenue from rent</CardTitle>
                <CardDescription>
                    Reading house: <strong>{house}</strong>
                </CardDescription>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Rent</TableCell>
                            <TableCell>{rent?.toFixed(2) ?? '--'}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Commisions</TableCell>
                            <TableCell>N/a</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Booking fee</TableCell>
                            <TableCell>{bookingFee?.toFixed(2) ?? '--'}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
})
InvoicesRevenueFromRent.displayName = 'InvoicesRevenueFromRent'
