import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query/react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { authApi } from 'redux/api/auth.api'
import { profileApi } from 'redux/api/profile.api'
import { authSlice } from 'redux/slices/auth.slice'
import { commandSlice } from 'redux/slices/command.slice'
import { profileSlice } from 'redux/slices/profile.slice'
import { themeSlice } from 'redux/slices/theme.slice'

export type RootState = ReturnType<typeof rootReducer>
type AppStore = ReturnType<typeof configureStore>
export type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const rootReducer = combineReducers({
    [themeSlice.name]: themeSlice.reducer,
    [commandSlice.name]: commandSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [profileSlice.name]: profileSlice.reducer,
    //
    [authApi.reducerPath]: authApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(authApi.middleware)
            .concat(profileApi.middleware),
})

setupListeners(store.dispatch)
