import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { CURRENCY } from 'config/constants.config'
import { getLocaleDate } from 'config/dayjs.config'

export interface GuestTableData {
    date: string
    consumptionType: string
    measurementFilter: string
    pricePerUnitThisDay: number
    startCount: number
    lastCount: number
    dayUsage: number
    dayCost: number
}

export const guestColumns: ColumnDef<GuestTableData>[] = [
    {
        accessorKey: 'date',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Date" buttonClassName="ml-0" />,
        cell: ({ row }) => {
            const date = row.getValue('date') as GuestTableData['date']
            return <>{getLocaleDate(date)}</>
        },
    },
    {
        accessorKey: 'consumptionType',
        header: 'Consumption type',
        cell: ({ row }) => {
            const consumptionType = row.getValue('consumptionType') as GuestTableData['consumptionType']
            return <span className="capitalize">{consumptionType}</span>
        },
    },
    {
        accessorKey: 'measurementFilter',
        header: 'Measurement filter',
    },
    {
        accessorKey: 'pricePerUnitThisDay',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Price per unit this day" />,
        cell: ({ row }) => {
            const pricePerUnitThisDay = row.getValue('pricePerUnitThisDay') as GuestTableData['pricePerUnitThisDay']
            return (
                <>
                    {pricePerUnitThisDay?.toFixed(2)} {CURRENCY}
                </>
            )
        },
    },
    {
        accessorKey: 'startCount',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Start count" />,
        cell: ({ row }) => {
            const startCount = row.getValue('startCount') as GuestTableData['startCount']
            return <>{startCount?.toFixed(2)}</>
        },
    },
    {
        accessorKey: 'lastCount',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Last count" />,
        cell: ({ row }) => {
            const lastCount = row.getValue('lastCount') as GuestTableData['lastCount']
            return <>{lastCount?.toFixed(2)}</>
        },
    },
    {
        accessorKey: 'dayUsage',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Day usage" />,
        cell: ({ row }) => {
            const dayUsage = row.getValue('dayUsage') as GuestTableData['dayUsage']
            return <>{dayUsage?.toFixed(2)}</>
        },
    },
    {
        accessorKey: 'dayCost',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Day cost" />,
        cell: ({ row }) => {
            const dayCost = row.getValue('dayCost') as GuestTableData['dayCost']
            return (
                <>
                    {dayCost?.toFixed(2)} {CURRENCY}
                </>
            )
        },
    },
]
