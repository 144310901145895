import { FullPageLoader } from 'components/full-page-loader'
import { NumberSelect } from 'components/number-select'
import { InvoicesHotelGuestsUsage } from 'pages/invoices/components/invoices-hotel-guests-usage'
import { InvoicesInvoicePeriod } from 'pages/invoices/components/invoices-invoice-period'
import { InvoicesRevenueFromRent } from 'pages/invoices/components/invoices-revenue-from-rent'
import { InvoicesSummary } from 'pages/invoices/components/invoices-summary'
import { memo, useEffect, useMemo, useState } from 'react'
import {
    useGetDataMutation,
    useGetQuarterCountsMutation,
    useGetQuartersMutation,
    useGetRevenueMutation,
} from 'redux/api/profile.api'
import { useAppSelector } from 'redux/store'
import { getCurrentQuarter, getCurrentYear, getQuarterString } from 'utils/get-current-data'

interface InvoicesPageProps {}
export const InvoicesPage: React.FC<InvoicesPageProps> = memo(() => {
    const { revenue, estimations, invoicePeriodData, quartersData, guestsUsage, pending } = useAppSelector(state => state.profile)
    const { email } = useAppSelector(state => state.auth)
    const [getData] = useGetDataMutation()
    const [getRevenue] = useGetRevenueMutation()
    const [getQuarters] = useGetQuartersMutation()
    const [getQuarterCounts] = useGetQuarterCountsMutation()

    const [selectedYear, setSelectedYear] = useState<number>(() => getCurrentYear())
    const [selectedQuarter, setSelectedQuarter] = useState<number>(() => getCurrentQuarter())

    const bookingFee = useMemo(() => Number(revenue?.rent) * 0.3 ?? 0, [revenue.rent])

    const currentQuarterString = useMemo(() => {
        const str = getQuarterString(selectedQuarter)
        return `Quarter ${selectedQuarter} (${str})`
    }, [selectedQuarter])
    const selectedYearQuarters = useMemo(
        () => quartersData.find(item => item.year === selectedYear)?.quarters ?? [],
        [quartersData, selectedYear],
    )

    useEffect(() => {
        getData(email)
    }, [email, getData])

    useEffect(() => {
        getRevenue({
            email,
            quarter: selectedQuarter,
            year: selectedYear,
        })
    }, [email, getRevenue, selectedQuarter, selectedYear])

    useEffect(() => {
        getQuarters(email)
    }, [email, getQuarters])

    useEffect(() => {
        getQuarterCounts({
            email,
            quarter: selectedQuarter,
            year: selectedYear,
        })
    }, [email, getQuarterCounts, selectedQuarter, selectedYear])

    useEffect(() => {
        setSelectedQuarter(selectedYearQuarters?.at(-1) ?? getCurrentQuarter())
    }, [selectedYearQuarters])

    if (pending) {
        return <FullPageLoader />
    }

    return (
        <>
            <div className="flex justify-between">
                <InvoicesRevenueFromRent
                    rent={Number(revenue.rent) ?? 0}
                    bookingFee={bookingFee}
                    house={Number(estimations?.[0]?.billingCode) ?? 0}
                />
                <div className="flex gap-4">
                    <NumberSelect
                        data={quartersData.find(item => item.year === selectedYear)?.quarters ?? []}
                        selected={selectedQuarter}
                        setSelected={setSelectedQuarter}
                        label="Quarter"
                        placeholder="Quarter"
                    />
                    <NumberSelect
                        data={quartersData?.map(item => item.year)}
                        selected={selectedYear}
                        setSelected={setSelectedYear}
                        label="Year"
                        placeholder="Year"
                    />
                </div>
            </div>

            <InvoicesInvoicePeriod data={invoicePeriodData} currentQuarterString={currentQuarterString} />

            <InvoicesHotelGuestsUsage guestsUsage={guestsUsage} />

            <InvoicesSummary
                rent={Number(revenue.rent) ?? 0}
                bookingFee={bookingFee}
                guestsConsumption={guestsUsage?.guestsUsageTotal ?? 0}
                totaslHouseConsumption={invoicePeriodData.total ?? 0}
            />

            <div className="pt-2" />
        </>
    )
})
InvoicesPage.displayName = 'InvoicesPage'
