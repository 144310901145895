import { CurrentTheme } from 'components/hoc/current-theme'
import { Logo } from 'components/logo'
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar'
import { Button } from 'components/ui/button'
import { InlineCode } from 'components/ui/code'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu'
import { COMMAND_MENU_SHORTCUT, THEMES, defaultIconProps, themesIcons } from 'config/constants.config'
import { urlConfig } from 'config/urls.config'
import { LogIn, LogOut } from 'lucide-react'
import { memo, useCallback, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { setIsCommandOpenAction } from 'redux/slices/command.slice'
import { setThemeAction } from 'redux/slices/theme.slice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { getFallbackLetters } from 'utils/get-fallback-letters'
import { ThemeSelector } from './theme-selector.tsx'

interface NavbarProps {}
export const Navbar: React.FC<NavbarProps> = memo(() => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { isAuth, email } = useAppSelector(state => state.auth)
    const { theme } = useAppSelector(state => state.theme)

    const isHome = useMemo(() => location.pathname === urlConfig.home, [location.pathname])

    const onCommandOpen = useCallback(() => {
        dispatch(setIsCommandOpenAction(true))
    }, [dispatch])

    const onLogIn = useCallback(() => {
        navigate(urlConfig.login)
    }, [navigate])

    const onLogOut = useCallback(() => {
        navigate(urlConfig.logout)
    }, [navigate])

    return (
        <div className="bg-background/90 fixed z-50 w-full whitespace-nowrap border-b backdrop-blur-sm">
            <div className="container flex w-full items-center justify-between bg-none py-3">
                {/* logo */}
                <div className="flex items-center gap-2">
                    <Link to={urlConfig.home} className="text-lg font-bold">
                        <Logo />
                    </Link>
                </div>

                <nav className="flex items-center gap-2">
                    {/* command menu */}
                    <Button
                        variant="none"
                        onClick={onCommandOpen}
                        className="border-input bg-background text-muted-foreground ring-offset-background focus-visible:ring-ring flex h-9 w-48 items-center justify-between rounded-md border px-3 text-sm font-normal transition-all duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                    >
                        Search...
                        <InlineCode className="text-xs">{COMMAND_MENU_SHORTCUT}</InlineCode>
                    </Button>

                    {/* links / always */}
                    {!isHome && (
                        <Button asChild variant="ghost" size="sm">
                            <Link to={urlConfig.home}>Home</Link>
                        </Button>
                    )}
                    <Button asChild variant="ghost" size="sm">
                        <Link to={urlConfig.about}>About</Link>
                    </Button>

                    {/* links / auth */}
                    {isAuth && (
                        <>
                            <Button asChild variant="ghost" size="sm">
                                <Link to={urlConfig.invoices}>Invoices</Link>
                            </Button>
                            <Button asChild variant="ghost" size="sm">
                                <Link to={urlConfig.guest}>My House</Link>
                            </Button>
                        </>
                    )}

                    {/* theme */}
                    <ThemeSelector />

                    {/* avatar */}
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <Avatar className="h-9 w-9">
                                <AvatarImage src="" />
                                <AvatarFallback className="uppercase">{getFallbackLetters(email)}</AvatarFallback>
                            </Avatar>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {isAuth ? (
                                <DropdownMenuItem onClick={onLogOut} className="flex gap-2">
                                    <LogOut {...defaultIconProps} size={19} /> <span>Log out</span>
                                </DropdownMenuItem>
                            ) : (
                                <DropdownMenuItem onClick={onLogIn} className="flex gap-2">
                                    <LogIn {...defaultIconProps} size={19} /> <span>Log in</span>
                                </DropdownMenuItem>
                            )}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </nav>
            </div>
        </div>
    )
})
Navbar.displayName = 'Navbar'
