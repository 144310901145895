import { FullPageLoader } from 'components/full-page-loader'
import { NumberSelect } from 'components/number-select'
import { CardDescription, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { DatePickerWithRange } from 'components/ui/date-picker-with-range'
import { COUNTS } from 'config/constants.config'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useGetCountersDataMutation, useGetPeriodsMutation } from 'redux/api/profile.api'
import { useAppSelector } from 'redux/store'
import { changeUnits } from 'utils/change-units'
import { getCurrentYear } from 'utils/get-current-data'
import { GuestTableData, guestColumns } from './components/guest-columns'
import { GuestCountSelect } from './components/guest-count-select'
import { GuestEnergyMonthTotal } from './components/guest-energy-month-total'
import { GuestMonthSelect } from './components/guest-month-select'

interface GuestPageProps {}
export const GuestPage: React.FC<GuestPageProps> = memo(() => {
    const { newCounters, periods } = useAppSelector(state => state.profile)
    const { isAuth, email } = useAppSelector(state => state.auth)
    const { pending } = useAppSelector(state => state.profile)
    const [getPeriods] = useGetPeriodsMutation()
    const [getCountersData] = useGetCountersDataMutation()

    const [date, setDate] = useState<DateRange | undefined>()
    const [countType, setCountType] = useState<string>(COUNTS[0])
    const [years, setYears] = useState<
        Array<{
            billingCode: string
            year: number
            month: number[]
        }>
    >([])
    const [year, setYear] = useState<number>(getCurrentYear())
    const [months, setMonths] = useState<number[]>([])
    const [month, setMonth] = useState<number>()

    const filteredData = useMemo(() => {
        const tableData: GuestTableData[] = newCounters?.map(
            ({ day, start_count, last_count, day_usage, unit, price, multiprice }, index, array) => {
                const costMulter = multiprice ? multiprice : array[index + 1]?.multiprice ?? 0
                const pricePerUnitThisDay = price ? price + costMulter : newCounters[newCounters.length - 1].price + costMulter
                const dayCost = day_usage * pricePerUnitThisDay

                return {
                    date: day,
                    consumptionType: countType,
                    measurementFilter: changeUnits(unit),
                    pricePerUnitThisDay,
                    startCount: start_count,
                    lastCount: last_count,
                    dayUsage: day_usage,
                    dayCost,
                } satisfies GuestTableData
            },
        )
        if (!tableData) {
            return []
        }
        if (!date || !date.from || !date.to) {
            return tableData
        }
        return tableData?.filter(report => {
            if (!date.from || !date.to) return
            const reportDate = new Date(report.date)
            return reportDate >= date.from && reportDate <= date.to
        })
    }, [countType, date, newCounters])

    const isClearVisible = useMemo(() => {
        if (date) {
            return true
        }
        return false
    }, [date])

    const onClear = useCallback(() => {
        setDate(undefined)
    }, [])

    useEffect(() => {
        if (isAuth) {
            getPeriods(email)
        }
    }, [email, getPeriods, isAuth])

    useEffect(() => {
        if (!periods.length) {
            return
        }
        const [firstPeriod] = periods
        const [firstMonth] = firstPeriod.month

        setMonths(firstPeriod.month)
        setYears(periods)

        setYear(firstPeriod.year)
        setMonth(firstMonth)
    }, [periods])

    useEffect(() => {
        if (year && month && countType) {
            getCountersData({ email, countType, year, month })
        }
    }, [countType, email, getCountersData, month, year])

    useEffect(() => {
        if (!(periods.length && year)) {
            return
        }
        const { month } = periods.find(el => el.year == year)
        const [firstMonth] = month
        if (month) {
            setMonths(month)
            setMonth(firstMonth)
        }
    }, [periods, year])

    if (pending) {
        return <FullPageLoader />
    }

    return (
        <div className="space-y-5">
            <div className="flex justify-between gap-5">
                <div className="flex gap-8">
                    <GuestCountSelect value={countType} onValueChange={setCountType} />
                    <NumberSelect
                        data={years?.map(item => item.year) ?? []}
                        selected={year ?? getCurrentYear()}
                        setSelected={value => setYear(Number(value))}
                        label="Year"
                        placeholder="Year"
                    />
                    <GuestMonthSelect months={months} value={String(month)} onValueChange={value => setMonth(Number(value))} />
                </div>

                <div className="flex flex-col gap-1">
                    <CardDescription>Reading house:</CardDescription>
                    <CardTitle>{periods.length !== 0 && periods[0].billingCode}</CardTitle>
                </div>
            </div>

            <GuestEnergyMonthTotal countsPerMonth={newCounters} />

            <DataTable
                columns={guestColumns}
                data={filteredData}
                pagination
                initialSorting={{
                    column: 'date',
                    direction: 'desc',
                }}
            >
                <DataTableToolbar isClearVisible={isClearVisible} onClear={onClear}>
                    <DatePickerWithRange date={date} onDateChange={setDate} />
                </DataTableToolbar>
            </DataTable>

            <div className="py-1" />
        </div>
    )
})
GuestPage.displayName = 'GuestPage'
