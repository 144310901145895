import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { getLocaleDate } from 'config/dayjs.config'
import { memo, useEffect, useMemo } from 'react'
import { useGetProfileMutation } from 'redux/api/profile.api'
import { useAppSelector } from 'redux/store'

interface HomeProfileProps {}
export const HomeProfile: React.FC<HomeProfileProps> = memo(() => {
    const { profiles, nextTwentyOneDays, estimations } = useAppSelector(state => state.profile)
    const { email } = useAppSelector(state => state.auth)
    const [getProfileMutation] = useGetProfileMutation()

    const showTable = useMemo(() => {
        if (nextTwentyOneDays[0]?.Reservations && nextTwentyOneDays[0].Reservations.length > 0) {
            return true
        }
        return false
    }, [nextTwentyOneDays])

    useEffect(() => {
        getProfileMutation({
            email,
        })
    }, [email, getProfileMutation])

    return (
        <div className="mx-auto flex w-full max-w-2xl flex-col items-center justify-center gap-8 pt-0">
            <Card className="w-full bg-card/80 backdrop-blur">
                <CardHeader>
                    <CardTitle>Your reservations</CardTitle>
                    <CardDescription>
                        Reading house: <strong>{estimations?.[0]?.billingCode ?? '--'}</strong>
                    </CardDescription>
                </CardHeader>
                {profiles?.length !== 0 && (
                    <CardContent>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Reservation number</TableHead>
                                    <TableHead>Start date</TableHead>
                                    <TableHead>End date</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {profiles?.map((prof, index) => {
                                    return (
                                        <TableRow key={prof.reservationNumber ?? index}>
                                            <TableCell>{prof.reservationNumber ?? '--'}</TableCell>
                                            <TableCell>
                                                {getLocaleDate(prof.startUtc) ?? '--'}
                                            </TableCell>
                                            <TableCell>
                                                {getLocaleDate(prof.endUtc) ?? '--'}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </CardContent>
                )}
            </Card>

            <Card className="w-full bg-card/80 backdrop-blur">
                <CardHeader>
                    <CardTitle>Next 21 days reservations:</CardTitle>
                </CardHeader>
                <CardContent>
                    {showTable ? (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Reservation number</TableHead>
                                    <TableHead>Start date</TableHead>
                                    <TableHead>End date</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {nextTwentyOneDays[0]?.Reservations.map(reservation => {
                                    return (
                                        <TableRow key={reservation['Id']}>
                                            <TableCell>{reservation['Number']}</TableCell>
                                            <TableCell>
                                                {getLocaleDate(reservation['StartUtc'])}
                                            </TableCell>
                                            <TableCell>
                                                {getLocaleDate(reservation['EndUtc'])}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    ) : (
                        <p className="text-light">No bookings to display</p>
                    )}
                </CardContent>
            </Card>

            {estimations[0] && (
                <Card className="w-full bg-card/80 backdrop-blur">
                    <CardHeader>
                        <CardTitle>Future booking estimates:</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Time span</TableHead>
                                    <TableHead>Rent</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Comming reservations</TableCell>
                                    <TableCell>
                                        {estimations[0].commingReservationsEstimation}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Current Quarter</TableCell>
                                    <TableCell>{estimations[0].currentQuarterEstimation}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Next Quarter</TableCell>
                                    <TableCell>{estimations[0].nextQuarterEstimation}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            )}
        </div>
    )
})
HomeProfile.displayName = 'HomeProfile'
