import { Navbar } from 'components/navbar'
import { memo } from 'react'
import { Outlet } from 'react-router-dom'

interface MainLayoutProps {}
export const MainLayout: React.FC<MainLayoutProps> = memo(() => {
    return (
        <>
            <Navbar />
            <div className="container h-full min-h-screen space-y-5 pt-20 transition-all duration-300 animate-in fade-in-0">
                <Outlet />
            </div>
        </>
    )
})
MainLayout.displayName = 'MainLayout'
