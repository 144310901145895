/* eslint-disable react-refresh/only-export-components */

import { Moon, Sparkle, Sun } from 'lucide-react'

export const defaultIconProps = {
    strokeWidth: 1.5,
}

export const themesIcons = {
    light: <Sun {...defaultIconProps} />,
    dark: <Moon {...defaultIconProps} />,
    system: <Sparkle {...defaultIconProps} />,
}

export const THEMES: Array<{
    name: string
    icon: JSX.Element
}> = [
    {
        name: 'light',
        icon: themesIcons.light,
    },
    {
        name: 'dark',
        icon: themesIcons.dark,
    },
    {
        name: 'system',
        icon: themesIcons.system,
    },
]

export const COMMAND_MENU_SHORTCUT = `${/[mac|apple]/gi.test(navigator.userAgent) ? '⌘' : 'Ctrl'} K` as const

export const CURRENCY = 'SEK' as const

export const ENERGUY_UNIT = 'kWh' as const
export const WATER_UNIT = 'm3' as const

export const COUNTS = ['electricity', 'heat', 'water', 'cooling'] as const

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
] as const

export const QUARTERS = ['Jan 1 - Mar 31', 'Apr 1 - June 30', 'July 1 - September 30', 'October 1 - December 31'] as const
