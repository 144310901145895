import { AuthLayout } from 'components/layouts/auth-layout'
import { MainLayout } from 'components/layouts/main-layout'
import { urlConfig } from 'config/urls.config'
import { AboutPage } from 'pages/about-page'
import { AdminPage } from 'pages/admin-page'
import { GuestPage } from 'pages/guest/guest-page'
import { HomePage } from 'pages/home/home-page'
import { IndexPage } from 'pages/index-page'
import { InvoicesPage } from 'pages/invoices/invoices-page'
import { LoginPage } from 'pages/login-page'
import { LogoutPage } from 'pages/logout-page'
import { NoBookingsPage } from 'pages/no-bookings-page'
import { NotFoundPage } from 'pages/not-found-page'
import { memo } from 'react'
import { useRoutes } from 'react-router-dom'

interface RoutesProps {}
export const Routes: React.FC<RoutesProps> = memo(() => {
    return useRoutes([
        {
            path: urlConfig.home,
            element: <HomePage />,
        },
        // main layout
        {
            path: urlConfig.main,
            element: <MainLayout />,
            children: [
                // auth required
                {
                    path: urlConfig.main,
                    element: <AuthLayout />,
                    children: [
                        {
                            path: urlConfig.invoices,
                            element: <InvoicesPage />,
                        },
                        {
                            path: urlConfig.guest,
                            element: <GuestPage />,
                        },
                    ],
                },
                // no auth required
                {
                    path: urlConfig.about,
                    element: <AboutPage />,
                },
                {
                    path: urlConfig.admin,
                    element: <AdminPage />,
                },
                {
                    path: urlConfig.noBookings,
                    element: <NoBookingsPage />,
                },
                {
                    path: urlConfig.login,
                    element: <LoginPage />,
                },
                {
                    path: urlConfig.logout,
                    element: <LogoutPage />,
                },

                // layout other
                {
                    path: urlConfig.callback,
                    element: <IndexPage />,
                },
                {
                    index: true,
                    element: <IndexPage />,
                },
            ],
        },
        // other
        {
            path: '*',
            element: <NotFoundPage />,
        },
    ])
})
Routes.displayName = 'Routes'
