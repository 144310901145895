import { FullPageLoader } from 'components/full-page-loader'
import { urlConfig } from 'config/urls.config'
import { memo, useEffect } from 'react'
import { clearBookingsAction } from 'redux/slices/profile.slice'
import { useAppDispatch } from 'redux/store'

interface LogoutPageProps {}
export const LogoutPage: React.FC<LogoutPageProps> = memo(() => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(clearBookingsAction())
        window.location.href = `${urlConfig.baseURL}${urlConfig.logout}`
    }, [dispatch])

    return <FullPageLoader />
})
LogoutPage.displayName = 'LogoutPage'
