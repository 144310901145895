import { createSlice } from '@reduxjs/toolkit'
import { profileApi } from 'redux/api/profile.api'
import { ProfileType } from 'types/profile.type'

export interface ProfileSliceState {
    energyCounts: ProfileType.GetDataResponse['parsedData']
    pricePerUnit: ProfileType.GetDataResponse['pricePerUnitThisDay']['pricePerUnit']
    countFactors: ProfileType.GetDataResponse['factors']
    billingCode: ProfileType.GetDataResponse['billingCode']
    // eslint-disable-next-line
    profiles: any[]
    guestsUsage: {
        totalHouseConsumption: number
        billingServiceSum: number
        guestsElectricitySum: number
        guestsElectricityConsumption: number
        guestsHeatSum: number
        guestsHeatConsumption: number
        guestsCoolingSum: number
        guestsCoolingConsumption: number
        guestsWaterSum: number
        guestsWaterConsumption: number
        guestsUsageTotal: number
        groupedRent: Record<string, ProfileType.ExcludedOwnersReservation[]>
        excludedOwnersReservations: ProfileType.ExcludedOwnersReservation[]
    }
    pending: boolean
    error: string
    // eslint-disable-next-line
    periods: any[]
    // eslint-disable-next-line
    newCounters: any[]
    quartersData: Array<{
        year: number
        quarters: number[]
    }>
    invoicePeriodData: {
        year: string
        quarter: string
        total: number
        electricity: ProfileType.EHCWData
        heat: ProfileType.EHCWData
        cooling: ProfileType.EHCWData
        water: ProfileType.EHCWData
    }
    nextTwentyOneDays: Array<{
        AgeCategories?: unknown
        BusinessSegments?: unknown
        ChannelManagerReservations?: unknown
        Companies?: unknown
        Cursor: string
        Customers?: unknown
        Items?: unknown
        Notes?: unknown
        OrderItems?: unknown
        Products?: unknown
        QrCodeData?: unknown
        RateGroups?: unknown
        Rates?: unknown
        ReservationGroups?: unknown
        Reservations: Array<{
            Id: string
            ServiceId: string
            GroupId: string
            Number: string
            ChannelNumber: string
            StartUtc: string
            EndUtc: string
        }>
        ResourceAccessTokens?: unknown
        ResourceCategories?: unknown
        ResourceCategoryAssignments?: unknown
        Resources?: unknown
        Services?: unknown
    }>
    estimations: Array<{
        billingCode: string
        comingReservationsEstimation?: number
        currentQuarterEstimation?: number
        nextQuarterEstimation?: number
        commingReservationsEstimation?: number
    }>
    revenue: {
        rent: string
        commissions: number
        bookingFee: number
    }
}

const initialState: ProfileSliceState = {
    profiles: [],
    nextTwentyOneDays: [],
    estimations: [],
    guestsUsage: {
        totalHouseConsumption: 0,
        billingServiceSum: 0,
        guestsElectricitySum: 0,
        guestsElectricityConsumption: 0,
        guestsHeatSum: 0,
        guestsHeatConsumption: 0,
        guestsCoolingSum: 0,
        guestsCoolingConsumption: 0,
        guestsWaterSum: 0,
        guestsWaterConsumption: 0,
        guestsUsageTotal: 0,
        excludedOwnersReservations: [],
        groupedRent: {},
    },
    pending: true,
    error: '',
    periods: [],
    newCounters: [],
    billingCode: '',
    quartersData: [],
    invoicePeriodData: {
        year: '',
        quarter: '',
        total: 0,
        cooling: {
            counterEnds: 0,
            counterEndsDate: '',
            counterStarts: 0,
            counterStartsDate: '',
            quarterCost: 0,
            quarterUsage: 0,
        },
        electricity: {
            counterEnds: 0,
            counterEndsDate: '',
            counterStarts: 0,
            counterStartsDate: '',
            quarterCost: 0,
            quarterUsage: 0,
        },
        heat: {
            counterEnds: 0,
            counterEndsDate: '',
            counterStarts: 0,
            counterStartsDate: '',
            quarterCost: 0,
            quarterUsage: 0,
        },
        water: {
            counterEnds: 0,
            counterEndsDate: '',
            counterStarts: 0,
            counterStartsDate: '',
            quarterCost: 0,
            quarterUsage: 0,
        },
    },
    energyCounts: {
        electricity: {},
        water: {},
        cooling: {},
        heat: {},
    },
    pricePerUnit: {
        electricity: [],
        water: [],
        cooling: [],
        heat: [],
    },
    countFactors: {
        electricity: 0,
        water: 0,
        cooling: 0,
        heat: 0,
    },
    revenue: {
        rent: '',
        commissions: 0,
        bookingFee: 0,
    },
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        clearBookingsAction: state => {
            state.profiles = []
            state.nextTwentyOneDays = []
            state.estimations = []
        },
    },
    extraReducers: builder => {
        builder
            // TODO replace any
            // eslint-disable-next-line
            .addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, action: any) => {
                state.profiles = action.payload.bookings
                state.nextTwentyOneDays = action.payload.nextTwentyOneDays
                state.estimations = action.payload.estimations
            })
            .addMatcher(profileApi.endpoints.getData.matchPending, state => {
                state.pending = true
            })
            // TODO replace any
            // eslint-disable-next-line
            .addMatcher(profileApi.endpoints.getData.matchFulfilled, (state, action: any) => {
                if (action.payload === 'not a MEWS customer') {
                    state.error = 'not a MEWS customer'
                    state.pending = false
                    return
                }
                state.energyCounts = action.payload[0]?.parsedData
                state.pricePerUnit = action.payload[1]?.pricePerUnitThisDay.pricePerUnit
                state.countFactors = action.payload[1]?.factors
                state.billingCode = action.payload[2]?.billingCode
                state.pending = false
                state.error = ''
            })
            // TODO replace any
            // eslint-disable-next-line
            .addMatcher(profileApi.endpoints.getPeriods.matchFulfilled, (state, action: any) => {
                state.periods = action.payload
            })
            .addMatcher(profileApi.endpoints.getCountersData.matchPending, state => {
                state.pending = true
            })
            .addMatcher(profileApi.endpoints.getCountersData.matchFulfilled, (state, action) => {
                if (action.payload === 'not a MEWS customer') {
                    state.error = 'not a MEWS customer'
                } else {
                    // @ts-expect-error error
                    state.newCounters = action.payload
                }
                state.pending = false
            })
            // TODO replace any
            // eslint-disable-next-line
            .addMatcher(profileApi.endpoints.getRevenue.matchFulfilled, (state, action: any) => {
                state.revenue = action.payload
                state.guestsUsage = action.payload.guestsUsage
            })
            // TODO replace any
            // eslint-disable-next-line
            .addMatcher(profileApi.endpoints.getQuarters.matchFulfilled, (state, action: any) => {
                state.quartersData = action.payload
            })
            // TODO replace any
            .addMatcher(
                profileApi.endpoints.getQuarterCounts.matchFulfilled,
                // eslint-disable-next-line
                (state, action: any) => {
                    state.invoicePeriodData = action.payload
                },
            )

            //

            // handle errors

            //

            .addMatcher(profileApi.endpoints.getProfile.matchRejected, (state, action) => {
                state.error = action.error.message || 'Failed to fetch profile'
                state.pending = false
            })
            .addMatcher(profileApi.endpoints.getData.matchRejected, (state, action) => {
                state.error = action.error.message || 'Failed to fetch energy'
                state.pending = false
            })
            .addMatcher(profileApi.endpoints.getPeriods.matchRejected, (state, action) => {
                state.error = action.error.message || 'Failed to fetch periods'
                state.pending = false
            })
            .addMatcher(profileApi.endpoints.getCountersData.matchRejected, (state, action) => {
                state.error = action.error.message || 'Failed to fetch counter data'
                state.pending = false
            })
            .addMatcher(profileApi.endpoints.getRevenue.matchRejected, (state, action) => {
                state.error = action.error.message || 'Failed to fetch revenue'
                state.pending = false
            })
    },
})

export const { clearBookingsAction } = profileSlice.actions
