import { App } from 'app'
import { AuthProvider } from 'components/hoc/auth-provider'
import { ReduxProvider } from 'components/hoc/redux-provider'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'styles.css'

const element = document.getElementById('root') as HTMLElement
const root = createRoot(element)

root.render(
    <AuthProvider>
        <ReduxProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ReduxProvider>
    </AuthProvider>
)
