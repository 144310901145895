import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { CURRENCY } from 'config/constants.config'
import { memo, useMemo } from 'react'

interface GuestEnergyMonthTotalProps {
    // eslint-disable-next-line
    countsPerMonth: any[]
}
export const GuestEnergyMonthTotal: React.FC<GuestEnergyMonthTotalProps> = memo(({ countsPerMonth }) => {
    const cost = useMemo(
        () =>
            countsPerMonth[countsPerMonth.length - 1]?.multiprice
                ? countsPerMonth[countsPerMonth.length - 1].price + countsPerMonth[countsPerMonth.length - 1].multiprice
                : countsPerMonth[countsPerMonth.length - 1]?.price,
        [countsPerMonth],
    )

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead>Month total</TableHead>
                    <TableHead>Usage</TableHead>
                    <TableHead>Cost</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                <TableRow>
                    <TableCell />
                    <TableCell className="font-bold">{countsPerMonth?.[0]?.month_usage?.toFixed(3)}</TableCell>
                    <TableCell className="font-bold">
                        {(countsPerMonth && countsPerMonth[0]?.month_usage * cost).toFixed(2)} {CURRENCY}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
})
GuestEnergyMonthTotal.displayName = 'GuestEnergyMonthTotal'
