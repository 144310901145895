import { memo } from 'react'
import { cn } from 'utils/cn'

interface InlineCodeProps extends React.ComponentProps<'code'> {
    children: string | string[]
}
export const InlineCode: React.FC<InlineCodeProps> = memo(({ className, ...props }) => {
    return (
        <code
            {...props}
            className={cn(
                'bg-muted text-muted-foreground relative rounded px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold',
                className,
            )}
        />
    )
})
InlineCode.displayName = 'InlineCode'

interface CodeProps extends React.ComponentProps<'code'> {}
export const Code: React.FC<React.PropsWithChildren<CodeProps>> = memo(({ className, children, ...props }) => {
    return (
        <code {...props} className="font-mono text-sm font-medium">
            <pre className={cn('bg-muted rounded px-4 py-5', className)}>{children}</pre>
        </code>
    )
})
Code.displayName = 'Code'
