import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { Children, cloneElement, memo, useEffect, useState } from 'react'
import { cn } from 'utils/cn'
import { DataTablePagination } from './data-table-pagination'
import { DataTableToolbar } from './data-table-toolbar'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from './table'

interface DataTableProps<TData, TValue> extends React.ComponentProps<'div'> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    //
    pagination?: boolean
    caption?: string
    initialSorting?: {
        column: string
        direction: 'asc' | 'desc'
    }
    initialHidden?: string[]
    headClassName?: string
    cellClassName?: string
    //
    children?: React.ReactElement<typeof DataTableToolbar>
}

export const DataTableNoMemo = <TData, TValue>({
    columns,
    data,
    //
    pagination,
    caption,
    initialSorting,
    initialHidden,
    headClassName,
    cellClassName,
    //
    children,
    ...props
}: DataTableProps<TData, TValue>) => {
    const [sorting, setSorting] = useState<SortingState>([])
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
        ...initialHidden?.map(col => ({ [col]: false }))?.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
    })

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            columnVisibility,
            columnFilters,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    // sort columns
    useEffect(() => {
        if (!initialSorting) {
            return
        }
        const sortColumn = table.getColumn(initialSorting?.column)
        if (!sortColumn) {
            console.error(`[DataTable Sorting] Column ${initialSorting.column} not found`)
            return
        }
        sortColumn.toggleSorting(initialSorting?.direction === 'asc' ? false : true)
    }, [initialSorting, table])

    // hide columns
    // useEffect(() => {
    //     if (!initialHidden) {
    //         return
    //     }
    //     const allColumns = table.getAllColumns()
    //     allColumns.forEach(column => {
    //         if (initialHidden.includes(column.id)) {
    //             column.toggleVisibility()
    //         }
    //     })
    // }, [initialHidden, table])

    return (
        <div {...props}>
            {Children.map(children, child => cloneElement(child as React.ReactElement, { table }))}
            <Table className={cn(children && 'mt-3')}>
                {caption && <TableCaption className="pb-4">{caption}</TableCaption>}
                <TableHeader>
                    {table.getHeaderGroups().map(group => (
                        <TableRow key={group.id}>
                            {group.headers.map(header => (
                                <TableHead key={header.id} className={headClassName}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map(row => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <TableCell key={cell.id} className={cn('h-14', cellClassName)}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-20 text-center">
                                No results
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {pagination && <DataTablePagination table={table} />}
        </div>
    )
}

export const DataTable = memo(DataTableNoMemo) as typeof DataTableNoMemo
