import { createSlice } from '@reduxjs/toolkit'
import { authApi } from 'redux/api/auth.api'

interface AuthSliceState {
    isAuth: boolean
    email: string
    isLoading: boolean
}

const initialState: AuthSliceState = {
    isAuth: false,
    email: '',
    isLoading: true,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearAuth: state => {
            state.isAuth = false
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(authApi.endpoints.checkIsAuth.matchFulfilled, (state, action) => {
                state.isAuth = action.payload.status
                state.email = action.payload.email
            })
            .addMatcher(authApi.endpoints.checkIsAdmin.matchPending, state => {
                state.isLoading = true
            })
            .addMatcher(authApi.endpoints.checkIsAdmin.matchFulfilled, (state, action) => {
                state.isAuth = action.payload.status
                state.email = action.payload.email
                state.isLoading = false
            })
    },
})

export const { clearAuth } = authSlice.actions
